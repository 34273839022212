import React, { FC, ReactElement } from 'react'

import { KlosySVGId } from '~components/homepage/svg/types/svg'

export const FeblikLogo: FC = (): ReactElement => (
  <symbol id={KlosySVGId.feblikLogo} viewBox="0 0 1000 372">
    <g
      transform="translate(0.000000,372.000000) scale(0.100000,-0.100000)"
      fill="#000000"
      stroke="none"
    >
      <path
        fill="#dc4d35"
        d="M382 2358 c4 -301 10 -334 76 -430 88 -129 90 -167 13 -288 -74 -117
-91 -175 -91 -315 l0 -115 140 0 140 0 0 93 c1 162 14 220 76 328 30 53 58
113 61 133 l6 36 303 0 c240 0 304 3 304 13 0 7 -21 46 -47 87 -25 41 -52 90
-60 108 l-13 32 -305 0 c-282 0 -306 1 -313 18 -3 9 -7 87 -7 172 l0 155 363
3 362 2 0 120 0 120 -506 0 -506 0 4 -272z"
      />
      <path
        fill="#dc4d35"
        d="M1640 2376 c0 -297 7 -333 91 -456 47 -70 50 -78 46 -125 -3 -37 -16
-70 -48 -125 -76 -127 -81 -148 -87 -312 l-4 -148 516 0 516 0 0 115 0 115
-376 0 -377 0 7 58 c4 42 22 89 66 177 33 66 60 130 60 142 0 12 5 25 11 29 6
3 149 7 319 8 170 0 311 4 315 8 4 4 9 26 12 50 4 36 -1 52 -29 98 l-33 55
-355 0 -354 0 -7 33 c-4 18 -8 90 -10 160 l-4 127 378 3 377 2 0 120 0 120
-515 0 -515 0 0 -254z"
      />
      <path
        fill="#dc4d35"
        d="M2942 2443 c4 -212 7 -223 91 -346 68 -99 67 -117 -14 -275 -32 -65
-64 -141 -69 -171 -6 -29 -10 -141 -10 -247 l0 -194 368 0 c395 0 426 3 539
55 70 31 147 105 179 170 32 66 42 177 25 267 -16 81 -72 199 -116 243 -19 19
-35 37 -35 40 0 3 21 15 48 27 73 32 151 87 167 119 28 54 20 81 -67 210 -98
144 -140 190 -206 223 -108 55 -139 58 -536 63 l-368 5 4 -189z m740 -76 c65
-32 151 -121 169 -174 14 -43 -3 -72 -58 -99 -33 -16 -66 -19 -240 -22 l-201
-3 -42 61 c-52 77 -76 131 -85 192 -13 84 -21 81 203 77 192 -4 198 -5 254
-32z m-5 -539 c67 -31 103 -98 103 -187 -1 -91 -58 -166 -145 -190 -22 -6
-123 -11 -227 -11 l-188 0 0 124 c0 97 4 138 21 191 33 107 29 105 226 99 138
-5 176 -10 210 -26z"
      />
      <path
        fill="#dc4d35"
        d="M4320 2571 c0 -95 18 -156 67 -229 94 -141 94 -145 6 -320 l-68 -134
-3 -339 -3 -339 470 0 471 0 0 115 0 115 -332 2 -333 3 3 230 c2 127 8 248 13
270 6 22 35 90 66 150 66 134 66 151 -5 268 -62 103 -64 107 -71 192 l-6 70
-137 3 -138 3 0 -60z"
      />
      <path
        fill="#dc4d35"
        d="M5520 2616 c0 -27 21 -71 71 -148 75 -115 75 -133 1 -267 -34 -60
-67 -128 -73 -152 -8 -28 -13 -185 -16 -441 l-5 -398 141 0 141 0 0 383 c0
210 5 410 10 445 8 59 34 121 97 235 39 72 37 120 -10 193 -21 32 -49 83 -63
112 l-25 52 -135 0 c-110 0 -134 -3 -134 -14z"
      />
      <path
        fill="#dc4d35"
        d="M6120 2624 c0 -3 25 -47 55 -98 73 -122 73 -147 5 -265 -89 -155 -84
-120 -88 -613 l-3 -438 141 0 140 0 0 258 0 258 75 74 c60 60 77 72 84 60 5
-8 107 -158 226 -332 l217 -318 164 0 164 0 -68 98 c-278 399 -512 742 -512
750 0 25 192 180 375 302 105 71 182 144 211 202 13 26 24 52 24 58 0 12 -329
14 -346 2 -5 -4 -16 -23 -24 -42 -41 -107 -79 -147 -260 -273 -80 -55 -185
-134 -233 -175 -49 -40 -91 -71 -94 -69 -13 14 23 120 72 212 69 128 70 153
14 249 -66 113 -53 106 -206 106 -73 0 -133 -3 -133 -6z"
      />
      <path
        fill="#dc4d35"
        d="M8970 1730 l0 -530 90 0 90 0 0 530 0 530 -90 0 -90 0 0 -530z"
      />
      <path
        fill="#dc4d35"
        d="M8335 1894 c-22 -8 -57 -28 -77 -45 l-38 -30 0 41 0 40 -90 0 -90 0
0 -490 0 -490 90 0 90 0 0 180 0 180 44 -34 c113 -86 294 -68 401 39 144 143
129 432 -28 556 -81 65 -209 87 -302 53z m143 -165 c59 -29 92 -81 99 -157 7
-74 -8 -119 -53 -165 -118 -118 -315 -27 -314 145 1 145 142 238 268 177z"
      />
      <path
        fill="#dc4d35"
        d="M7620 1413 c-61 -20 -90 -57 -90 -115 0 -116 172 -148 217 -41 27 65
-1 126 -70 152 -30 12 -34 12 -57 4z"
      />
    </g>
  </symbol>
)
