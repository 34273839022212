import React, { FC, ReactElement } from 'react'
import styled from 'styled-components'

import { Klos } from '~components/homepage/svg/icons/klos'
import { SlideMarker } from '~components/homepage/svg/icons/slide-marker'
import { LogoCircle } from '~components/homepage/svg/icons/logo-circle'
import { KlosyLogo } from '~components/homepage/svg/icons/klosy-logo'
import { Chef210 } from '~components/homepage/svg/icons/chef210'
import { Chef240 } from '~components/homepage/svg/icons/chef240'
import { CrossCollapse } from '~components/homepage/svg/icons/cross-collapse'
import { visuallyHidden } from '~components/homepage/styled/helpers'
import { Santoku } from './santoku'
import { Filleting } from './filleting'
import { Serrated } from './serrated'
import { Peeling } from './peeling'

import { LightAsianCleaver } from './LightAsianCleaver'
import { Cleaver } from './cleaver'
import { Yanagiba } from './yanagiba'
import { Boning } from './boning'

import { Cross } from './cross'
import { Punching } from './punching'
import { KlosSide } from './klos-side'
import { InfoSign } from './info-sign'
import { Arrow } from './arrow'
import { ArrowDown } from './arrow-down'
import { LogoMini } from './logo-mini'
import { HamburgerMenu } from './hamburger'
import { Star } from './star'
import { StarEmpty } from './star-empty'
import { ErrorSign } from './error-sign'
import { Checked } from './checked'
import { Minus } from './minus'
import { Plus } from './plus'
import { FeblikLogo } from './feblik-logo'
import { FeblikBanner } from './feblik-banner'

const symbols: FC[] = [
  Klos,
  SlideMarker,
  LogoCircle,
  KlosyLogo,
  Chef210,
  Chef240,
  Santoku,
  Filleting,
  Serrated,
  Peeling,
  LightAsianCleaver,
  Cleaver,
  Yanagiba,
  Boning,
  Cross,
  Punching,
  KlosSide,
  InfoSign,
  CrossCollapse,
  Arrow,
  ArrowDown,
  LogoMini,
  HamburgerMenu,
  Star,
  StarEmpty,
  ErrorSign,
  Checked,
  Minus,
  Plus,
  FeblikLogo,
  FeblikBanner,
]

interface Props {
  className?: string
}

const KlosySVGSymbols: React.FC<Props> = ({
  className,
}): React.ReactElement => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    viewBox="0 0 0 0"
    width={0}
    height={0}
    className={className}
  >
    <defs>
      {symbols.map(
        (Symbol: FC): ReactElement => (
          <Symbol key={Symbol.name} />
        )
      )}
    </defs>
  </svg>
)

export default styled(KlosySVGSymbols)`
  ${visuallyHidden}
`
