export enum Align {
  left = 'left',
  right = 'right',
  center = 'center',
}

export interface SectionWrapperProps {
  bgColor?: string
}

export interface ContentContainerProps {
  maxWidth?: string
  padding?: string
}

export interface ResponsiveImageProps {
  src: string
  height: string
  width?: string
  center?: boolean
}

export interface AlignProp {
  align?: Align
}

export interface ContentHeaderProps {
  mB?: string
  size?: string
}

export interface TitleHeaderProps {
  margin?: string
  color?: string
  size?: string
}

export interface Image {
  url: string
  alt?: string
  title: string
}
